
/**
 * The Interval class represents a Mathematical Interval. The object is constructed from a string
 * describing the interval eg. [0, 1], [40, 50), (-1, 1). To create an infinite endpoint leave the
 * place blank eg. [0,), (,0]
 */
export class Interval {

    _lower = {
        open: false,
        infinite: false,
        value: null
    }

    _upper = {
        open: false,
        infinite: false,
        value: null
    }

    rangeRegex = /(\(|\[)([^,]*),([^,]*)(\)|\])/gm;

    constructor(serialized) {
        let parsed = this.rangeRegex.exec(serialized)
        if (parsed !== null) {
            this._lower.open = this._isOpen(parsed[1])
            this._lower.infinite = this._isInfinite(parsed[2])
            this._lower.value = this._parseValue(parsed[2])
            this._upper.open = this._isOpen(parsed[4])
            this._upper.infinite = this._isInfinite(parsed[3])
            this._upper.value = this._parseValue(parsed[3])
        } else {
            throw `Invalid interval: ${serialized}`
        }
    }

    /**
     * Checks if a value is contained inside the interval
     * @param {*} value The value to check
     */
    contains(value) {
        let satisfiesLower = this._lower.infinite ? true : false        
        if (this._lower.open && value > this._lower.value) {
            satisfiesLower = true
        } else if (!this._lower.open && value >= this._lower.value) {
            satisfiesLower = true
        }

        let satisfiesUpper = this._upper.infinite ? true : false
        if (this._upper.open && value < this._upper.value) {
            satisfiesUpper = true
        } else if (!this._upper.open && value <= this._upper.value) {
            satisfiesUpper = true
        }

        return satisfiesLower && satisfiesUpper
    }

    _isOpen(symbol) {
        if (symbol == "(" || symbol == ")") {
            return true
        }
        return false
    }

    _isInfinite(value) {
        if (value.trim() == "") {
            return true
        }
        return false
    }

    _parseValue(value) {
        if (this._isInfinite(value)) {
            return null
        } else if (isNaN(value)) {
            throw `Invalid interval: '${value}' is not a number!`
        }
        return value * 1
    }

}

/**
 * The ColorClass class binds a color to an interval
 */
export class ColorClass {

    constructor(serializedInterval, color) {
        this.interval = new Interval(serializedInterval)
        this.color = color
    }

}

/**
 * Given some ColorClasses, ColorClassifier can map values to colors. 
 */
export class ColorClassifier {

    _classes = []

    /**
     * Given an object with intervals as keys and colors as values the constructor
     * initializes the instance with the given ColorClasses. 
     * eg. new ColorClassifier({
     *   "[0, 50)": "#00ff00", 
     *   "[50, 100]": "#ff0000"
     * })
     * @param {*} classesObject 
     */
    constructor(classesObject) {
        let keys = Object.keys(classesObject)
        keys.forEach(key => {
            this._classes.push(new ColorClass(
                key, 
                classesObject[key]
            ))
        })
    }

    addClass(colorClass) {
        this._classes.push(colorClass)
    }

    /**
     * Given a value, returns the color of the matching class or null.
     * @param {*} value The value to check against the color classes
     * @returns A color
     */
    getColor(value) {
        for (let i = 0; i < this._classes.length; i++) {
            let colorClass = this._classes[i]
            if (colorClass.interval.contains(value)) {
                return colorClass.color
            }
        }
        return null
    }

}
