<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <div class="header-container fixed-top">
            <header class="header navbar navbar-expand-sm">
                <ul class="navbar-item theme-brand flex-row text-center">
                    <!-- <li class="nav-item theme-logo">
                        <router-link to="/">
                            <img src="@/assets/images/logo.svg" class="navbar-logo" alt="logo" />
                        </router-link>
                    </li> -->
                    <li class="nav-item theme-text">
                        <router-link to="/" class="nav-link"> 
                            <img src="@/assets/images/space-logo-xs.png" alt="Space Hellas" class="space-logo" /> 
                            CITYSENSE 
                        </router-link>
                    </li>
                </ul>

                <div class="d-none horizontal-menu">
                    <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-menu"
                        >
                            <line x1="3" y1="12" x2="21" y2="12"></line>
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </a>
                </div>

                <div class="navbar-item flex-row ml-md-auto">
                    <b-dropdown toggle-tag="a" variant="icon-only" toggle-class="nav-link" class="nav-item language-dropdown">
                        <template #button-content>
                            <img v-if="selectedLang" :src="require(`@/assets/images/flags/${selectedLang.code}.png`)" class="flag-width" alt="flag" />
                        </template>
                        <perfect-scrollbar>
                            <b-dropdown-item
                                v-for="item in countryList"
                                :key="item.code"
                                link-class="d-flex align-items-center"
                                :active="$i18n.locale === item.code"
                                @click.prevent="changeLanguage(item)"
                            >
                                <img :src="require(`@/assets/images/flags/${item.code}.png`)" class="flag-width" alt="" /> <span>{{ item.name }}</span>
                            </b-dropdown-item>
                        </perfect-scrollbar>
                    </b-dropdown>

                    <b-dropdown toggle-tag="a" variant="icon-only" toggle-class="user nav-link" class="nav-item user-profile-dropdown" :right="true">
                        <template #button-content>
                            <div class="initials">{{ profileInitials }}</div>
                            <!-- PG<img src="@/assets/images/profile-16.jpeg" alt="avatar" /> -->
                        </template>

                        <!-- <b-dropdown-item to="/users/profile">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-user"
                            >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                            Profile
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider> -->
                        <b-dropdown-item>
                            <span class="text-muted">{{ profileName }}</span>
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="logout()">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-log-out"
                            >
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                <polyline points="16 17 21 12 16 7"></polyline>
                                <line x1="21" y1="12" x2="9" y2="12"></line>
                            </svg>
                            {{ $t("logout") }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </header>
        </div>
        <!--  END NAVBAR  -->

        <!--  BEGIN NAVBAR  -->
        <div class="sub-header-container">
            <header class="header navbar navbar-expand-sm">
                <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-menu"
                    >
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </a>

                <!-- Portal vue for Breadcrumb -->
                <portal-target name="breadcrumb"> </portal-target>
            </header>
        </div>
        <!--  END NAVBAR  -->
    </div>
</template>
<script>
    export default {
        data() {
            return {
                selectedLang: null,
                countryList: this.$store.state.countryList
            };
        },

        computed: {
            profileName() {
                let profile = this.$keycloak.idTokenParsed
                let displayName = "NA"
                if ("name" in profile) {
                    displayName = profile.name
                } else if ("preferred_username" in profile) {
                    displayName = profile.preferred_username
                } else if ("email" in profile) {
                    displayName = profile.email
                }

                return displayName
            },

            profileInitials() {
                let profile = this.$keycloak.idTokenParsed
                // console.log(profile);

                let displayName = "NA"
                if ("name" in profile) {
                    displayName = profile.name
                } else if ("preferred_username" in profile) {
                    displayName = profile.preferred_username
                }

                let initials = ""
                let splittedName = displayName.split(" ")
                if (splittedName.length > 1) {
                    initials = splittedName[0][0] + splittedName[1][0]
                } else {
                    initials = splittedName[0].slice(0, 2)
                }

                return initials
            }
        },

        mounted() {
            this.selectedLang = this.$appSetting.toggleLanguage();

            this.toggleMode();
        },

        methods: {
            toggleMode(mode) {
                this.$appSetting.toggleMode(mode);
            },

            changeLanguage(item) {
                this.selectedLang = item;
                this.$appSetting.toggleLanguage(item);
            },

            logout() {
                this.$router.push("/");
                this.$keycloak.logout();
            }
        }
    };
</script>

<style>
.header .dropdown .dropdown-toggle .initials {
     width: 28px;
     height: 28px;
     line-height: 28px;
     font-weight: bold;
     border-radius: 2px;
     color: #fff;
     background-color: #4a4c5d;
}

.header .space-logo {
    height: 16px;
    vertical-align: baseline;
    opacity: 0.5;
}
</style>
