<template>
    <span class="padded-text" v-html="paddedWords">
    </span>
</template>

<script>
    export default {
        props: {
            text: String,
            padding_character: {
                type: String,
                default: "_"
            }
        },        

        computed: {
            words() {
                return this.text.split(/\s+/)
            },

            paddedWords() {
                let output = ""
                this.words.forEach(word => {
                    let parsedWord = word.replaceAll(this.padding_character, "&nbsp;")
                    output += `<span class="pt-word">${parsedWord}</span> `
                })
                return output
            }
        }
    }
</script>

<style lang="scss">
.padded-text .pt-word {
    white-space: nowrap;
}
</style>
