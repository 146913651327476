<template>
    <!-- BEGIN FOOTER -->
    <div class="footer-wrapper">
        <div class="footer-section f-section-1">
            <p class="">Copyright © 2022 Draxis Environmental SA, All rights reserved.</p>
        </div>
    </div>
    <!-- END FOOTER -->
</template>
<script>
    export default {
        data() {
            return {};
        },
        mounted() {},
        methods: {}
    };
</script>
