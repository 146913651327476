<template>
    <div class="layout-px-spacing dash_1">
        <portal to="breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active" aria-current="page"><span>{{ $t('overview') }}</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </portal>

        <div class="row layout-top-spacing">
            <!-- Left Column -->           
            <div class="col-xxxl-2 col-xxl-3 col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <div class="widget">
                            <div class="widget-content">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <wfs-value
                                            title="Λαμπτήρες"
                                            :features="wifiHotspotFeatures"
                                            property="Consumption_KWh"
                                            aggregation="count"
                                            units="Σύνολο"
                                            color="#cece13"
                                        >        
                                            <template #header-icon>
                                                <i class="fa-solid fa-lightbulb"></i>
                                            </template>                 
                                        </wfs-value>                    
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                                        <wfs-fraction
                                            title="Σε λειτουργία________"
                                            title_style="font-size: 14px; font-weight: normal"
                                            :features="wifiHotspotFeatures"
                                            property="Consumption_KWh"
                                            :property_value="0"
                                            occurences_description="Σε λειτουργία"
                                            rest_description="Εκτός λειτουργιας"
                                            occurences_color="#cece13"
                                            :pie="true"
                                        />
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                                        <wfs-fraction
                                            title="Εκτός λειτουργιας"
                                            title_style="font-size: 14px; font-weight: normal"
                                            :features="wifiHotspotFeatures"
                                            property="Consumption_KWh"
                                            :property_value="1"
                                            occurences_description="Εκτός λειτουργιας"
                                            rest_description="Σε λειτουργία"
                                            occurences_color="#ff1313"
                                            :pie="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <div class="widget">
                            <div class="widget-content">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <wfs-value
                                            title="Κάδοι ____________"
                                            :features="trashBinFeatures"
                                            property="Consumption_KWh"
                                            aggregation="count"
                                            units=""
                                            color="#13ce81"
                                        >
                                            <template #header-icon>
                                                <i class="fa-solid fa-trash-can"></i>
                                            </template>
                                        </wfs-value>                               
                                    </div>

                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <wfs-value
                                            title="Μέση Πληρότητα"
                                            title_style="font-size: 14px; font-weight: normal; margin-bottom: 8px"
                                            :features="trashBinFeatures"
                                            property="Consumption_KWh"
                                            aggregation="avg"
                                            units="%"
                                            color="#13ce81"
                                            :value_decimals="0"
                                            :value_modifier='(value) => value * 100'
                                        />
                                    </div>                                    
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <wfs-fraction
                                            title="Λειτουργικοί"
                                            title_style="font-size: 14px; font-weight: normal"
                                            :features="trashBinFeatures"
                                            property="Device"
                                            :property_value="23"
                                            occurences_description="Λειτουργικοί"
                                            rest_description="Προβληματικοί"
                                            occurences_color="#13ce81"
                                            :pie="true"
                                        />                                                                                
                                    </div>

                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <wfs-pie-chart
                                            title="Κάδοι/Πληρότητα"
                                            title_style="font-size: 14px; font-weight: normal"
                                            :features="trashBinFeatures"
                                            :properties="[
                                                {name: 'Consumption_KWh', filter: (value) => value < 0.3, label: 'Άδειοι'},
                                                {name: 'Consumption_KWh', filter: (value) => value < 0.8, label: 'Στη Μέση'},
                                                {name: 'Consumption_KWh', filter: (value) => value >= 0.8, label: 'Γεμάτοι'},
                                            ]"
                                            :legend="false"
                                            :colors="['#13ce81', '#cece13', '#dd2323']"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>                  
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <div class="widget">
                            <div class="widget-content">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <wfs-value
                                        title="Σταθμοί AQ"
                                        :features="openAqFeatures"
                                        property="Consumption_KWh"
                                        aggregation="count"
                                        units="Σύνολο"
                                        color="#4361ee"
                                    >        
                                        <template #header-icon>
                                            <i class="fa-solid fa-leaf"></i>
                                        </template>             
                                    </wfs-value>                    
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <wfs-fraction
                                        title="Σε λειτουργία________"
                                        title_style="font-size: 14px; font-weight: normal"
                                        :features="openAqFeatures"
                                        property="Consumption_KWh"
                                        :property_value="0"
                                        occurences_description="Σε λειτουργία"
                                        rest_description="Εκτός λειτουργίας"
                                        occurences_color="#4361ee"
                                        :pie="true"
                                    />                                    
                                </div>

                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <wfs-fraction
                                        title="Εκτός λειτουργίας"
                                        title_style="font-size: 14px; font-weight: normal"
                                        :features="openAqFeatures"
                                        property="Consumption_KWh"
                                        :property_value="0"
                                        occurences_description="Εκτός λειτουργίας"
                                        rest_description="Σε λειτουργία"
                                        occurences_color="#ff1313"
                                        :pie="true"
                                    />                                    
                                </div>                                
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <div class="widget">
                            <div class="widget-content">
                            <div class="row">
                                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <wfs-value
                                        title="WiFi Hotspots"
                                        :features="wifiHotspotFeatures"
                                        property="Consumption_KWh"
                                        aggregation="count"
                                        units="Σύνολο"
                                        color="#ee4392"
                                        :value_decimals="0"
                                    >        
                                        <template #header-icon>
                                            <i class="fa-solid fa-wifi"></i>
                                        </template>            
                                    </wfs-value>                    
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <wfs-fraction
                                        title="Σε λειτουργία________"
                                        title_style="font-size: 14px; font-weight: normal"
                                        :features="wifiHotspotFeatures"
                                        property="Consumption_KWh"
                                        :property_value="1"
                                        occurences_description="Σε λειτουργία"
                                        rest_description="Εκτός λειτουργίας"
                                        occurences_color="#ee4392"
                                        :pie="true"
                                    />                                                                                               
                                </div>

                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <wfs-fraction
                                        title="Εκτός λειτουργίας"
                                        title_style="font-size: 14px; font-weight: normal"
                                        :features="wifiHotspotFeatures"
                                        property="Consumption_KWh"
                                        :property_value="0"
                                        occurences_description="Εκτός λειτουργίας"
                                        rest_description="Σε λειτουργία"
                                        occurences_color="#ff1313"
                                        :pie="true"
                                    />                                                                                               
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Middle Column -->
            <div class="col-xxxl-8 col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 layout-spacing">
                        <wfs-weather-summary 
                            title="Καιρός"
                            :features="weatherFeatures" 
                            property="value"                     
                            aggregation="avg"                     
                            temperature_color="#ce8113"
                            horizontal
                        />
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 layout-spacing">
                        <wfs-value 
                            title="Γενικός Δείκτης AQ" 
                            :features="weatherFeatures"
                            property="value"
                            aggregation="avg"                                        
                        >
                            <template #header-icon>
                                <i class="far fa-solid fa-leaf"></i>
                            </template>
                        </wfs-value>
                    </div>                    

                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 layout-spacing">
                        <wfs-value 
                            title="Μέση Κατανάλωση" 
                            :features="currentElectricityConsumptionFeatures" 
                            property="Consumption_KWh" 
                            aggregation="avg" 
                            units="KWh"
                            color="#a153fe"
                        >
                            <template #header-icon>
                                <i class="far fa-solid fa-plug"></i>
                            </template>                        
                        </wfs-value>                
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <wfs-map
                            title=""
                            :initial_location="[22.94700, 40.63410]"
                            :initial_zoom="14"
                            :basemap="{url: 'https://api.maptiler.com/tiles/satellite-v2/tiles.json?key=E1IwCQCKXkbWq8G0xW0R', tileSize: 512, crossOrigin: 'anonymous'}"
                            :basemap_opacity="0.8"
                            :feature_layers="[                        
                                {label: 'WiFi Hotspots', items: wifiHotspotFeatures, color: '#ff73ff', faicon: '\ue012'},                                                             
                                {label: 'Trash Bins', items: trashBinFeatures, color: '#33eea1', faicon: '\uf2ed'},                             
                                {label: 'AQ Alerts', items: aqAlertsFeatures, color: '#ff1313', faicon: '\uf5bd'},                             
                                {label: 'Electricity Consumption Alerts', items: electricityConsumptionAlertsFeatures, color: '#ff1313', faicon: '\ue55b'},                             
                            ]"
                            height="610px"
                            :receive_features_from="['alerts']"
                        />                  
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <wfs-chart 
                            title="Μηνιαία Κατανάλωση" 
                            type="bar"
                            :features="monthlyElectricityConsumptionFeatures" 
                            :properties="[{name: 'consumption_this_year', label: 'Τρέχον έτος'}, {name: 'consumption_previous_year', label: 'Προηγούμενο έτος'}]"
                            xaxis_property="ts_month_name" 
                            units="KWh"
                            :height="350"
                            :colors="['#a153fe', 'rgba(206, 129, 19, 1)']"
                        />
                    </div>                    
                </div>

                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <wfs-list 
                            title="Προβλήματα" 
                            type="line"
                            :features="allAlerts"
                            :properties="['device_id', 'metric', 'value']"
                            height="500px"
                            emit_features_as="alerts"
                            color="#ff1313"
                        />                  
                    </div>  
                </div>
            </div>

            <!-- Right Column -->
            <div class="col-xxxl-2 col-xxl-3 col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <div class="widget">
                            <div class="widget-content">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <wfs-value
                                            title="Θέσεις Στάθμευσης"
                                            :features="parkingSpotFeatures"
                                            property="Consumption_KWh"
                                            aggregation="count"
                                            units=""
                                            color="#53f0f0"
                                        >
                                            <template #header-icon>
                                                <i class="fa-solid fa-car-side"></i>
                                            </template>                                        
                                        </wfs-value>
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <wfs-fraction
                                            title="Κατειλημμένες"
                                            :features="parkingSpotFeatures"
                                            property="Consumption_KWh"
                                            :property_value="1"
                                            occurences_description="Κατειλημμένες"
                                            rest_description="Ελεύθερες"
                                            occurences_color="#53f0f0"
                                            :pie="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <wfs-value
                            title="Αιτήματα Πολιτών"
                            :features="parkingSpotFeatures"
                            property="Consumption_KWh"
                            aggregation="count"
                            units="Μη Επιλυμμένα"
                            color="#ce8113"
                        >                  
                            <template #header-icon>
                                <i class="fa-solid fa-user-tag"></i>
                            </template> 
                        </wfs-value>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <div class="widget">
                            <div class="widget-content">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <wfs-value
                                            title="Κάμερες"
                                            :features="openAqFeatures"
                                            property="Consumption_KWh"
                                            aggregation="count"
                                            units="Σύνολο"
                                            color="#7dee43"
                                        >        
                                            <template #header-icon>
                                                <i class="fa-solid fa-video"></i>
                                            </template>                 
                                        </wfs-value>                    
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <wfs-fraction
                                            title="Λειτουργικές"
                                            :features="openAqFeatures"
                                            property="Device"
                                            :property_value="28"
                                            occurences_description="Λειτουργικές"
                                            rest_description="Προβληματικές"
                                            occurences_color="#7dee43"
                                            :pie="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>

                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <wfs-pie-chart
                            title="Προβλήματα"
                            :features="allAlerts"
                            :properties="[
                                {name: 'metric', filter: (value) => value == 'temp', label: 'Λαμπτήρες'},
                                {name: 'threshold_type', filter: (value) => value == 'gt', label: 'Κάδοι'},
                                {name: 'metric', filter: (value) => value == 'rh', label: 'Σταθμοί AQ'},
                                {name: 'threshold_type', filter: (value) => value == 'gte', label: 'WiFi'},
                                {name: 'threshold_type', filter: (value) => value == 'gte' || 'gt', label: 'Κάμερες'},
                            ]"
                            :legend="true"
                            :colors="['#cece13', '#13ce81', '#4361ee', '#ee4392', '#7dee43']"
                        />
                    </div>                    
                </div>                

                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <div class="widget">
                            <div class="widget-heading">
                                <h5>Τηλέφωνα Υπηρεσιών</h5>
                            </div>
                            <div class="widget-content text-muted" style="font-size: 1.2em">
                                Προϊστάμενος Τεχνικών Υπηρεσιών: <span class="text-nowrap">2310 223120</span>
                                <br><br>
                                Βλάβες Φωτισμού: 2310 223122 <br>
                                Βλάβες Συσκευών WiFi: 2310 223123 <br>
                                Βλάβες Σταθμών AQ: 2310 223124 <br>
                                Βλάβες Έξυπνων Κάδων: 2310 223125 <br>
                                Βλάβες Καμερών: 2310 223126 <br>                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row">            
            <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-sales-category">
                    <div class="widget-heading">
                        <h5>Κάμερα 1</h5>
                    </div>
                    <div class="widget-content">
                        <iframe src="https://cdn.skylinewebcams.com/video/1097.mp4" sandbox="allow-scripts allow-same-origin allow-popups allow-forms" allow="autoplay" allowfullscreen="" loading="lazy" frameborder="0" marginheight="0" marginwidth="0" width="100%" height="250px"></iframe>                        
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-sales-category">
                    <div class="widget-heading">
                        <h5>Κάμερα 2</h5>
                    </div>
                    <div class="widget-content">
                        <iframe src="https://cdn.skylinewebcams.com/video/570.mp4" sandbox="allow-scripts allow-same-origin allow-popups allow-forms" allow="autoplay" allowfullscreen="" loading="lazy" frameborder="0" marginheight="0" marginwidth="0" width="100%" height="250px"></iframe>
                    </div>
                </div>
            </div>           
        </div> -->
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Wfs } from '@/libraries/wfs/wfs';
    import WfsValue from '@/components/wfs/wfs-value'
    import WfsFraction from '@/components/wfs/wfs-fraction'
    import WfsList from '@/components/wfs/wfs-list'
    import WfsMap from '@/components/wfs/wfs-map'
    import WfsWeatherSummary from '@/components/wfs/wfs-weather-summary'
    import WfsPieChart from '@/components/wfs/wfs-pie-chart'
    import WfsChart from '@/components/wfs/wfs-chart'
    import { Scheduler } from '@/libraries/scheduler';
    import { getEnv } from '@/libraries/environment';
    import feather from 'feather-icons';

    Vue.component('wfs-value', WfsValue);
    Vue.component('wfs-fraction', WfsFraction);
    Vue.component('wfs-list', WfsList);
    Vue.component('wfs-map', WfsMap);
    Vue.component('wfs-weather-summary', WfsWeatherSummary);
    Vue.component('wfs-pie-chart', WfsPieChart);
    Vue.component('wfs-chart', WfsChart);

    import '@/assets/sass/widgets/widgets.scss';

    export default {
        metaInfo: { title: 'Overview' },
        data() {
            return {                
                currentElectricityConsumptionFeatures: [],
                metricAlertsFeatures: [],
                sourceAlertsFeatures: [],
                areaAlertsFeatures: [],
                poiAlertsFeatures: [],
                weatherFeatures: [],
                trashBinFeatures: [],
                parkingSpotFeatures: [],                
                monthlyElectricityConsumptionFeatures: [],
                electricityConsumptionAlertsFeatures: [],
                aqAlertsFeatures: [],
                openAqFeatures: [],
                wifiHotspotFeatures: [],
            };
        },

        computed: {
            allAlerts() {
                let alerts = []
                
                this.metricAlertsFeatures.forEach(feature => alerts.push(feature))
                this.sourceAlertsFeatures.forEach(feature => alerts.push(feature))
                this.areaAlertsFeatures.forEach(feature => alerts.push(feature))
                this.poiAlertsFeatures.forEach(feature => alerts.push(feature))

                alerts.forEach(alert => {
                    let currentValue = alert.get("value")
                    alert.set("value", ((currentValue * 100) | 1) / 100)
                })

                return alerts
            },

            availableParkingSpots() {
                return this.parkingSpotFeatures.filter(feature => {
                    return feature.get("Consumption_KWh") == 0
                })
            },            
        },

        mounted() {
            feather.replace();
            
            this.wfs = new Wfs(getEnv("VUE_APP_CITYSENSE_WFS"));
            this.getEnergyFeatures()
            this.getAlertFeatures()
            this.getWeatherFeatures()
            this.getTrashBins()
            this.getParkingSpots()
            this.getOpenAq()
            this.getWifiHotspots()

            let scheduler = new Scheduler(this);
            scheduler.schedule(() => {this.getAlertFeatures()}, 8000)
        },

        methods: {
            getEnergyFeatures() {
                this.wfs.getFeatures("dts:materialized_senseone_latest_values")
                .then(function(response) {
                    this.currentElectricityConsumptionFeatures = response;
                }.bind(this))
                .catch(function(error) {
                    console.log(error);
                });
                
                this.wfs.getFeatures("dts:materialized_monthly_electricity_consumption_two_years")
                .then(function(response) {
                    this.monthlyElectricityConsumptionFeatures = response;
                }.bind(this))
                .catch(function(error) {
                    console.log(error);
                });                
            },

            getWeatherFeatures() {
                let now = new Date()
                let currentHour = new Date((new Date()).setHours(now.getHours(), 0, 0, 0)).toISOString()
                currentHour = currentHour.replace(".000", "")
                console.log(currentHour)                

                this.wfs.getFeatures("dts:weather_forecast", `timestamp='${currentHour}'`)
                .then(function(response) {
                    this.weatherFeatures = response;
                }.bind(this))
                .catch(function(error) {
                    console.log(error);
                });
            },            

            getAlertFeatures() {
                this.aqAlertsFeatures = []
                this.electricityConsumptionAlertsFeatures = []

                this.wfs.getFeatures("dts:ntf_metric_alerts", "alert=true")
                .then(function(response) {
                    this.metricAlertsFeatures = response;

                    this.metricAlertsFeatures.forEach(feature => {
                        if (feature.get("metric") == "pm10" || feature.get("metric") == "pm25" || feature.get("metric") == "pm1") {
                            this.aqAlertsFeatures.push(feature)
                        }
                        else if (feature.get("metric") == "electricity_consumption") {
                            this.electricityConsumptionAlertsFeatures.push(feature)
                        }
                    })

                    // this.metricAlertsFeatures = []
                }.bind(this))
                .catch(function(error) {
                    console.log(error);
                });

                this.wfs.getFeatures("dts:ntf_source_alerts", "alert=true")
                .then(function(response) {
                    this.sourceAlertsFeatures = response;

                    this.metricAlertsFeatures.forEach(feature => {
                        if (feature.get("metric") == "pm10" || feature.get("metric") == "pm25" || feature.get("metric") == "pm1") {
                            this.aqAlertsFeatures.push(feature)
                        }
                    })                    
                }.bind(this))
                .catch(function(error) {
                    console.log(error);
                });

                this.wfs.getFeatures("dts:ntf_area_alerts", "alert=true")
                .then(function(response) {
                    this.areaAlertsFeatures = response;

                    this.metricAlertsFeatures.forEach(feature => {
                        if (feature.get("metric") == "pm10" || feature.get("metric") == "pm25" || feature.get("metric") == "pm1") {
                            this.aqAlertsFeatures.push(feature)
                        }
                    })                    
                }.bind(this))
                .catch(function(error) {
                    console.log(error);
                });

                this.wfs.getFeatures("dts:ntf_poi_alerts", "alert=true")
                .then(function(response) {
                    this.poiAlertsFeatures = response;

                    this.metricAlertsFeatures.forEach(feature => {
                        if (feature.get("metric") == "pm10" || feature.get("metric") == "pm25" || feature.get("metric") == "pm1") {
                            this.aqAlertsFeatures.push(feature)
                        }
                    })                    
                }.bind(this))
                .catch(function(error) {
                    console.log(error);
                });
            },

            getTrashBins() {
                this.wfs.getFeatures("dts:rawdata_values_latest", "Sensor_Type='trash-bin'")
                .then(function(response) {
                    this.trashBinFeatures = response;
                }.bind(this))
                .catch(function(error) {
                    console.log(error);
                });              
            },             

            getParkingSpots() {
                this.wfs.getFeatures("dts:rawdata_values_latest", "Sensor_Type='parking-spot'")
                .then(function(response) {
                    this.parkingSpotFeatures = response;
                }.bind(this))
                .catch(function(error) {
                    console.log(error);
                });              
            }, 
            
            getOpenAq() {
                this.wfs.getFeatures("dts:materialized_rawdata_values_latest", "Device IN (26,27,28,29,30,31,32)", "Device ASC")
                .then(function(response) {
                    this.openAqFeatures = response;                    
                }.bind(this))
                .catch(function(error) {
                    console.log(error);
                });              
            },
            
            getWifiHotspots() {
                this.wfs.getFeatures("dts:materialized_rawdata_values_latest", "Device IN (39)", "Device ASC")
                .then(function(response) {
                    this.wifiHotspotFeatures = response;                    
                }.bind(this))
                .catch(function(error) {
                    console.log(error);
                });              
            },
        }
    };
</script>
