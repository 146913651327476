<template>
    <div class="widget widget-wfs-list">
        <div class="widget-heading">
            <h5 :style="title_style">
                <padded-text :text="title" />
            </h5>            
            <div class="w-icon">                
                <slot name="header-icon" />
            </div>            
        </div>

        <div class="widget-content" :style="`height: ${height}`">
            <b-table responsive striped hover :items="tableItems" :fields="tableProperties">
                 <template #cell(action)="row">
                    <a 
                        class="action"
                        :style="`color: ${color}`" 
                        href="javascript:" 
                        @click="$root.$emit('select-feature', {sender: emit_features_as, feature: row.item.getFeature()})"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                    </a>
                 </template>
            </b-table>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: String,
            features: Array,
            properties: Array,
            height: String,
            emit_features_as: String,
            color: String,
            title_style: {
                type: String,
                default: ""
            }
        },

        data() {
            return {
            }
        },

        computed: {
            tableProperties() {
                let output = []
                this.properties.forEach(property => {
                    if (typeof property == "string") {
                        output.push({key: property, label: property})
                    } else {
                        output.push(property)
                    }
                })
                if (this.emit_features_as) {
                    output.push({key: 'action', label: ''})
                }
                return output
            },

            tableItems() {
                let output = []                
                this.features.forEach(function(feature) {
                    output.push(this.toTableItem(feature))
                }.bind(this))
                return output
            }
        },

        mounted() {
        },

        methods: {
            toTableItem(feature) {
                // Add the property values
                let tableItem = {...feature.values_}                
                // Add reference to the actual feature. (This will be emitted when emit_features is enabled)
                tableItem._feature = feature
                tableItem.getFeature = function() {return this._feature}

                return tableItem
            }
        },

        watch: {
        }
    }
</script>

<style lang="scss" scoped>
@import '@/assets/base/_color_variables';

.widget-wfs-list .widget-content {
    overflow-y: auto;
}

.widget-wfs-list .widget-content .action {
    opacity: 1;
}

.widget-wfs-list .widget-content .action:hover {
    opacity: .75;
}
</style>
