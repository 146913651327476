<template>
    <div class="widget widget-wfs-value">
        <div class="widget-heading">
            <h5 :style="title_style">
                <padded-text :text="title" />
            </h5>
        </div>

        <div class="widget-content">
            <p class="value-container" :style="`color: ${color}`">                
                <span class="w-icon" :style="`color: ${color}`">                
                    <slot name="header-icon" />
                </span>            
                <formatted-value :value="value" :decimals="value_decimals"/>
                <span class="secondary">
                    <small>{{ units }}</small>
                </span>
            </p>

            <p v-if="compare_to_property != null" class="comparison" >
                <span v-if="change >= 0">+{{ change }}%</span>
                <span v-else>{{ change }}%</span>
                {{ comparison_text }}
            </p>
        </div>
        
    </div>
</template>

<script>
    import { FeatureAggregator } from '@/libraries/wfs/wfs';

    export default {
        props: {
            title: String,
            features: Array,
            property: String,
            aggregation: String,    
            units: String,
            compare_to_property: String,
            comparison_text: String,
            color: {
                type: String,
                default: "#4361ee"
            },
            title_style: {
                type: String,
                default: ""
            },
            value_decimals: {
                type: Number,
                default: null,
                required: false
            },
            value_modifier: {
                type: Function,
                default: null,
                required: false
            }        
        },

        data() {
            return {
                aggregator: new FeatureAggregator()
            }
        },

        computed: {
            value() {
                if (this.value_modifier == null) {
                    return this.aggregator.get(this.property)
                } else {
                    return this.value_modifier(this.aggregator.get(this.property))
                }
            },

            change() {
                let value = this.aggregator.get(this.property) - this.aggregator.get(this.compare_to_property);
                value = (value / this.aggregator.get(this.compare_to_property)) * 100
                if (value !== null) return value.toLocaleString();
                return null
            }
        },

        mounted() {
        },

        methods: {

        },

        watch: {
            features(features) {
                this.aggregator = new FeatureAggregator(features)
                this.aggregator.addAggregation(this.property, this.aggregation)
                if (this.compare_to_property != null) {
                    this.aggregator.addAggregation(this.compare_to_property, this.aggregation)
                }
                this.aggregator.aggregate()
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@/assets/base/_color_variables';

.widget-wfs-value .value-container {
    font-size: 30px;
    color: $primary
}
.widget-wfs-value .value-container .secondary {
    color: $m-color_6
}
.widget-wfs-value .comparison {
    color: $m-color_6
}
</style>
