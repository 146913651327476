<template>
    <div class="wfs-weather-icon">
        <div v-if="hasCelestialObject" class="part celestial-object" :class="hasClouds ? 'has-clouds' : ''">
            <img :src="require(`@/assets/images/weather/${celestialObject}.svg`)" />
        </div>
        <div v-if="hasClouds" class="part clouds" :class="hasCelestialObject ? 'has-celestial-object' : ''">    
            <img :src="require('@/assets/images/weather/cloud.svg')" />
        </div>
        <div v-if="hasRain" class="part rain" :class="`type-${rainType} ` + (hasCelestialObject ? 'has-celestial-object' : '')">    
            <img :src="require(`@/assets/images/weather/${rainType}.svg`)" />
        </div>
    </div>
</template>

<script>
    import feather from 'feather-icons';

    export default {
        props: {
            total_cloudines: Number,
            precipitation: Number
        },

        data() {
            return {
                hours: (new Date()).getHours()
            }
        },

        computed: {
            celestialObject() {
                if (this.hours < 19) {
                    return "sun"
                }
                return "moon"
            },

            hasCelestialObject() {
                if (this.total_cloudines < 70) return true
                return false
            },

            hasClouds() {  
                if (this.hasRain || this.total_cloudines > 30) return true
                return false
            },

            hasRain() {
                if (this.precipitation > 0.02) return true
                return false
            },

            rainType() {
                if (this.precipitation > 0.35) return "rain"
                return "drizzle"
            }
        },

        mounted() {
            feather.replace();
        },

        methods: {
        },

        watch: {
        }
    }
</script>

<style lang="scss" scoped>
@import '@/assets/base/_color_variables';

.wfs-weather-icon {
    position: relative;
    width: 46px;
    // height: 46px;
}

.wfs-weather-icon .part {
    position: absolute;
}

.wfs-weather-icon .celestial-object {
    z-index: 1;
    left: 23px;
}

.wfs-weather-icon .celestial-object.has-clouds {
    left: 0px;
}

.wfs-weather-icon .clouds {
    z-index: 3;
}

.wfs-weather-icon .clouds.has-celestial-object {
    top: 8px;
}

.wfs-weather-icon .rain {
    z-index: 2;
    left: 12px;
}

.wfs-weather-icon .rain.type-rain {
    top: 20px;
}

.wfs-weather-icon .rain.type-rain.has-celestial-object {
    top: 28px;
}

.wfs-weather-icon .rain.type-drizzle {
    left: 14px;
    top: 22px;
}

.wfs-weather-icon .rain.type-drizzle.has-celestial-object {
    top: 30px;
}
</style>
