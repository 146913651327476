import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/overview.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
    //overview
    { path: '/', name: 'Home', component: Home },

    //environment
    {
        path: '/environment',
        name: 'environment',
        component: () => import(/* webpackChunkName: "environment" */ '../views/environment.vue')
    },

    //traffic
    {
        path: '/traffic',
        name: 'traffic',
        component: () => import(/* webpackChunkName: "traffic" */ '../views/traffic.vue')
    },

    //energy
    {
        path: '/energy',
        name: 'energy',
        component: () => import(/* webpackChunkName: "energy" */ '../views/energy.vue')
    },

    //data sources
    {
        path: '/datasources',
        name: 'datasources',
        component: () => import(/* webpackChunkName: "datasources" */ '../views/datasources/datasources.vue')
    },
    {
        path: '/datasources/:id',
        name: 'datasource-overview',
        component: () => import(/* webpackChunkName: "datasources" */ '../views/datasources/datasource-overview.vue')
    },

    //notifications
    {
        path: '/notifications',
        name: 'notifications',
        component: () => import(/* webpackChunkName: "notifications" */ '../views/notifications/notifications.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }
    next(true);
});

export default router;
