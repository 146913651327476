<template>
    <div class="wfs-fraction-pie">
        <apexchart type="donut" :options="options" :series="series"></apexchart>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueApexCharts from 'vue-apexcharts';
    import { FeatureSeries } from '@/libraries/wfs/wfs';

    Vue.component('apexchart', VueApexCharts);

    export default {
        props: {
            total_label: String,
            occurences: Number,
            rest: Number,
            occurences_description: String,
            rest_description: String,
            occurences_color: String,
            rest_color: {
                type: String,
                default: "#1b2e4b"
            },
            size: {
                type: Number,
                default: undefined
            }
        },

        data() {
            return {
                seriesGenerator: new FeatureSeries()
            }
        },

        computed: {
            series() {
                return [this.occurences, this.rest]
            },

            options() {
                let options = {
                    legend: {show: false},
                    chart: { toolbar: { show: false } }, 
                    dataLabels: { enabled: false },  
                    plotOptions: {pie: {                          
                        donut: {
                            size: '75%',
                            labels: {
                                show: true,
                                name: {
                                    show: false,  
                                    color: "#ffffff"                              
                                },
                                value: {
                                    fontSize: "26px",
                                },
                                total: { 
                                    show: true, 
                                    label: this.occurences_description,                                 
                                    formatter: function () { return this.occurences }.bind(this) 
                                }
                            }
                        }
                    }},
                    grid: {
                        padding: {
                            left: 0,
                            right: 0
                        }
                    },                    
                    colors: [this.occurences_color, this.rest_color],
                    fill: { opacity: 1 },
                    stroke: {colors: ["transparent"]},                 
                    tooltip: { enabled: true, y: { formatter: function (val) { return val.toLocaleString() } } },
                    labels: [this.occurences_description, this.rest_description]
                }

                return options
            }
        },

        mounted() {
        },

        methods: {
        },
    }
</script>

<style lang="scss" scoped>
@import '@/assets/base/_color_variables';
</style>
