<template>
    <div class="wfs-fraction-progress" :style="`height: ${height}; background-color: ${rest_color}`">
        <div class="progress-bar" :style="`background-color: ${occurences_color}; width: ${progress}%`">            
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            progress: Number,
            occurences_color: {
                type: String,
                default: "#4361ee"
            },
            rest_color: {
                type: String,
                default: "#1b2e4b"
            },
            height: {
                type: String,
                default: "10px"
            }
        },

        data() {
            return {
            }
        },

        computed: {
            percentage() {
                if (this.demoninator == 0) {
                    return 0
                }
                return this.numerator /  this.denoninator * 100
            },
        },

        mounted() {
        },

        methods: {

        },

        watch: {
        }
    }
</script>

<style lang="scss" scoped>
@import '@/assets/base/_color_variables';

.wfs-fraction-progress {
    position: relative; 
    border-radius: 5px;
    overflow: hidden;
}

.wfs-fraction-progress .progress-bar {
    height: 100%;
}
</style>
