<template>
    <div class="widget widget-wfs-fraction">
        <div class="widget-heading">
            <h5 :style="title_style">
                <padded-text :text="title" />
            </h5>
        </div>

        <!--progress bar-->
        <div class="widget-content" v-if="!pie">            
            <p class="value" :style="`color: ${occurences_color}`">                
                {{ numerator }} <small style="wrap">{{ occurences_description }}</small>
                <br>
            </p>
            <div class="percentage">
                <wfs-fraction-progress
                    :progress="percentage"
                    :occurences_color="occurences_color"
                    :rest_color="rest_color"
                />                
            </div> 
            <p class="complementary-value mt-3" :style="rest_color ? `color: ${rest_color}` : ''">           
                {{ rest }} {{ rest_description }}
            </p>
        </div>

        <!-- pie -->
        <div class="widget-content" v-else>
            <wfs-fraction-pie
                :occurences="numerator"
                :occurences_description="occurences_description"
                :occurences_color="occurences_color"
                :rest="rest"
                :rest_description="rest_description"
                :rest_color="rest_color"
                :total_label="total_label"
                :size="pie_size"
            />     
        </div>

    </div>
</template>

<script>
    import Vue from 'vue';
    import { FeatureAggregator } from '@/libraries/wfs/wfs';
    import WfsFractionProgress from '@/components/wfs/wfs-fraction-progress';
    import WfsFractionPie from '@/components/wfs/wfs-fraction-pie';

    Vue.component('wfs-fraction-progress', WfsFractionProgress);
    Vue.component('wfs-fraction-pie', WfsFractionPie);

    export default {
        props: {
            title: String,
            features: Array,
            property: String,
            property_value: [String, Number, Boolean],    
            occurences_description: String,
            rest_description: String,
            occurences_color: {
                type: String,
                default: "#4361ee"
            },
            rest_color: {
                type: String,                
            },
            pie: {
                type: Boolean,
                default: false
            },
            pie_size: {
                type: Number,
                default: undefined
            },
            total_label: {
                type: String,
                default: ""
            },
            title_style: {
                type: String,
                default: ""
            }
        },

        data() {
            return {
                aggregator: new FeatureAggregator()
            }
        },

        computed: {
            numerator() {
                let counter = 0
                this.features.forEach(feature => {
                    if (feature.get(this.property) == this.property_value) {
                        counter ++
                    }
                })
                return counter
            },

            denominator() {
              return this.features.length  
            },

            rest() {
                return this.denominator - this.numerator
            },

            percentage() {
                if (this.demoninator == 0) {
                    return 0
                }
                return this.numerator /  this.denominator * 100
            },

            value() {
                let value = this.aggregator.get(this.property);
                if (value !== null) return value.toLocaleString();
                return null
            },

            change() {
                let value = this.aggregator.get(this.property) - this.aggregator.get(this.compare_to_property);
                value = (value / this.aggregator.get(this.compare_to_property)) * 100
                if (value !== null) return value.toLocaleString();
                return null
            }
        },

        mounted() {
        },

        methods: {

        },

        watch: {
            features(features) {
                this.aggregator = new FeatureAggregator(features)
                this.aggregator.addAggregation(this.property, this.aggregation)
                if (this.compare_to_property != null) {
                    this.aggregator.addAggregation(this.compare_to_property, this.aggregation)
                }
                this.aggregator.aggregate()
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@/assets/base/_color_variables';

.widget-wfs-fraction .value {
    font-size: 30px;
    color: $primary
}
.widget-wfs-fraction .value span {
    color: $m-color_6;
}
.widget-wfs-fraction .percentage .progress {
    width: 80%
}
.widget-wfs-fraction .complementary-value {
    color: $m-color_6;
}
</style>
