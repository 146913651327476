import Vue from "vue";

/**
 * This is a Vue component oriented timer for scheduling periodic tasks. The 
 * scheduled tasks will stop on the component "beforeDestroy" event.
 */
export class Scheduler {    

    constructor(vue) {
        Vue.$log.debug("Initializing timer...")
        this.timers = []

        vue.$once("hook:beforeDestroy", () => {
            this._destroy()
        })
    }

    /**
     * Schedule a periodic task.
     * @param {Function} callback The function that will be called every `interval` milliseconds
     * @param {Number} interval The period between each call in milliseconds
     */
    schedule(callback, interval) {
        Vue.$log.debug(`Sheduling callback every ${interval}ms`)
        let timer = setInterval(callback, interval)
        this.timers.push(timer)
    }

    _destroy() {
        Vue.$log.debug("Destroying timer...")
        this.timers.forEach(timer => {
            clearInterval(timer)
        })
        this.timers = []
    }

}
