<template>
    <div class="widget widget-wfs-pie-chart">
        <div class="widget-heading">
            <h5 :style="title_style">
                <padded-text :text="title" />
            </h5>
        </div>

        <div class="widget-content">
            <apexchart type="pie" :options="options" :series="series"></apexchart>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueApexCharts from 'vue-apexcharts';
    import { FeaturePieSeries } from '@/libraries/wfs/wfs';

    Vue.component('apexchart', VueApexCharts);

    export default {
        props: {
            title: String,
            features: Array,
            properties: Array,
            colors: Array,
            legend: {
                type: Boolean,
                default: true
            },
            title_style: {
                type: String,
                default: ""
            }            
        },

        data() {
            return {
                seriesGenerator: new FeaturePieSeries()
            }
        },

        computed: {
            series() {
                return this.seriesGenerator.getSeries()
            },

            labels() {
                return this.seriesGenerator.getLabels()
            },

            options() {
                let options = {
                    legend: {show: this.legend},
                    chart: { toolbar: { show: false } }, 
                    dataLabels: { enabled: false },  
                    plotOptions: {pie: {}},
                    colors: this.colors,
                    fill: { opacity: 1 },
                    stroke: {colors: ["transparent"]},                 
                    tooltip: { enabled: true, y: { formatter: function (val) { return val.toLocaleString() } } },
                    labels: this.labels,
                    grid: {
                        padding: {
                            left: 0,
                            right: 0
                        }
                    }                    
                }

                return options
            }
        },

        mounted() {
        },

        methods: {
        },

        watch: {
            features(features) {
                this.seriesGenerator = new FeaturePieSeries(features)
                this.properties.forEach(property => {
                    this.seriesGenerator.addSeries(property.name, property.filter, property.label)
                })         
                this.seriesGenerator.generate()
            }
        }        
    }
</script>

<style lang="scss" scoped>
@import '@/assets/base/_color_variables';
</style>
