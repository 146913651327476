import axios from 'axios';
import { GeoJSON } from 'ol/format';
import Feature from 'ol/Feature';

export class Wfs {

    constructor(url, version = "2.0.0") {
        this.url = url;
        this.version = version
    }

    getFeatures(layer, filter, sortby) {
        let promise = axios.get(this.url, {
            params: {
                service: "wfs",
                version: this.version,
                request: "GetFeature",
                outputformat: "json",
                typeNames: layer,
                cql_filter: filter,
                sortby: sortby,
            },
            transformResponse: (response) => {
                return new GeoJSON().readFeatures(response, {featureProjection: 'EPSG:3857'})
            }
        });
        return promise.then((response) => response.data)        
    }

}


export class FeatureAggregator {

    constructor(features) {
        this.features = features
        this.aggregations = {}
        this.calculated = false
    }

    /**
     * Request an aggregation. (This function won't calculate the requested results. For that @see aggregate() )
     * @param {String} property The property to aggregate
     * @param {String} aggregation The aggregations function (avg, min, max, sum, count)
     * @param {String} as Optionally rename the aggregated property (Helpful if you want to perform multiple aggregations on the same property)
     */
    addAggregation(property, aggregation, as) {        
        this.aggregations[as || property] = {
            property,
            function: aggregation
        };
    }

    /**
     * Calculate the requested aggregations
     */
    aggregate() {
        if (this.calculated) return;
        this.calculated = true;

        this.features.forEach(feature => {
            let keys = Object.keys(this.aggregations)
            keys.forEach(key => {
                let aggregation = this.aggregations[key]
                switch (aggregation.function) {
                    case "avg": {
                        if (!aggregation.sum) aggregation.sum = 0;
                        aggregation.sum += feature.get(aggregation.property) * 1;
                        aggregation.value = aggregation.sum / this.features.length;
                        break
                    }
                    case "sum": {
                        if (!aggregation.value) aggregation.value = 0;
                        aggregation.value += feature.get(aggregation.property) * 1;
                        break
                    }
                    case "min": {
                        let featureValue = feature.get(aggregation.property) * 1
                        if (!aggregation.value) aggregation.value = featureValue;
                        else if (featureValue < aggregation.value) aggregation.value = featureValue;
                        break
                    }                    
                    case "max": {
                        let featureValue = feature.get(aggregation.property) * 1
                        if (!aggregation.value) aggregation.value = featureValue;
                        else if (featureValue > aggregation.value) aggregation.value = featureValue;
                        break
                    }
                    case "count": {
                        if (!aggregation.value) aggregation.value = 0;
                        aggregation.value += 1;
                        break
                    }                                        
                    default:                        
                }
            })
        })
    }

    /**
     * Get the calculated value of a requested aggregation
     * @param {*} property The name of the property to get. If "as" was provided at the aggregation request then you must use that name.
     * @returns The aggregated value
     */
    get(property) {
        if (Object.hasOwn(this.aggregations, property) && Object.hasOwn(this.aggregations[property], "value"))
            return this.aggregations[property].value
        else
            return null
    }

    getAll() {
        let output = {}
        let properties = Object.keys(this.aggregations)
        properties.forEach(property => {
            output[property] = this.aggregations[property].value
        })
        return output
    }
    
}

/**
 * Helper class for generating ApexCharts compatible series & xAxis properties from Features 
 */
export class FeatureSeries {

    constructor(features) {
        this.features = features
        this.properties = {}
        this.series = []
        this.xAxisProperty = null
        this.xAxis = []
        this.generated = false
    }

    addSeries(property, label) {
        this.properties[property] = {  
            name: label,
            data: []
        };        
    }

    setXAxis(property) {
        this.xAxisProperty = property
    }

    generate() {
        if (this.generated) return;
        this.generated = true;

        let keys = Object.keys(this.properties)

        this.features.forEach(feature => {
            keys.forEach(property => {
                let value = feature.get(property) * 1 || null
                this.properties[property].data.push(value)
            })

            // Generate x-axis
            if (this.xAxisProperty) {
                let value = feature.get(this.xAxisProperty)
                this.xAxis.push(value)
            }
        })
        
        keys.forEach(property => {
            this.series.push(this.properties[property])
        })
    }

    getSeries() {
        return this.series
    }

    getXAxis() {
        return this.xAxis
    }

}

/**
 * Helper class for generating ApexCharts Pie & Radial chart compatible series & labels from Features 
 */
export class FeaturePieSeries {
    
    constructor(features) {
        this.features = features
        this.properties = []
        this.series = []
        this.labels = []
        this.generated = false
    }

    addSeries(property, filter, label) {
        this.properties.push({
            name: property,
            label: label,
            filter: filter,
            count: 0
        })
    }

    generate() {
        if (this.generated) return;
        this.generated = true

        // Count
        this.features.forEach(feature => {
            this.properties.forEach(property => {
                let value = feature.get(property.name)
                if (property.filter(value) == true) {
                    property.count += 1
                }
            })
        })

        this.properties.forEach(property => {
            // Series
            this.series.push(property.count)

            // Labels
            this.labels.push(property.label)
        })
    }

    getSeries() {
        return this.series
    }

    getLabels() {
        return this.labels
    }    

}

/**
 * Hepler class for pivoting feauture properties based on a common property 
 * (i.e. group by timestamp and pivot sensor with value)
 */
export class FeaturePivoter {

    constructor(features, groupProperty, keyProperty, valueProperty) {
        this.features = features 
        this.groupProperty = groupProperty
        this.keyProperty = keyProperty
        this.valueProperty = valueProperty
        this.pivotedFeatures = []
        this.processed = false       
    }

    process() {
        if (this.processed) return;
        this.processed = true

        this.pivotedFeatures = []

        let grouped = {}

        this.features.forEach(feature => {
            // Group
            let groupKey = feature.get(this.groupProperty)
            let groupFeature = null
            if (!Object.prototype.hasOwnProperty.call(grouped, groupKey)) {
                groupFeature = new Feature()
                groupFeature.set(this.groupProperty, groupKey)
                grouped[groupKey] = groupFeature 
            } else {
                groupFeature = grouped[groupKey]
            }

            // Pivot
            let key = feature.get(this.keyProperty)
            let value = feature.get(this.valueProperty)
            if (key !== null) {
                groupFeature.set(key, value)
            }
        })

        // Convert to array
        let groupsKeys = Object.keys(grouped)
        groupsKeys.forEach(groupKey => {            
            this.pivotedFeatures.push(grouped[groupKey])
        })
    }

    getFeatures() {
        if (!this.processed) this.process();
        return this.pivotedFeatures
    }

}
