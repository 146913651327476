<template>
    <b-modal :id="id" :title="title" size="fit" centered>
        <div v-for="layer in layers" :key="layer.label">
            <h4 class="modal-heading mb-6 mt-2 text-muted">{{ layer }}</h4>
            <b-table responsive bordered hover :items="getLayerProperties(layer)" />
        </div>

        <template #modal-footer>            
            <b-button variant="default" data-dismiss="modal" @click="$bvModal.hide(id)"><i class="flaticon-cancel-12"></i> Close</b-button>
        </template>
    </b-modal>        
</template>

<script>
    export default {
        props: {
            id: String,
            title: String,
            layered_features: Object,
        },

        data() {
            return {
            }
        },

        computed: {
            layers() {                
                return Object.keys(this.layered_features)
            }
        },

        mounted() {
        },

        methods: {
            getLayerProperties(layerName) {
                let output = []
                this.layered_features[layerName].features.forEach(feature => {
                    let properties = feature.getProperties()
                    delete properties.geometry
                    output = output.concat(properties)
                })
                return output
            }
        },

        watch: {
        }
    }
</script>

<style lang="scss" scoped>
@import '@/assets/base/_color_variables';
</style>

<style lang="scss">
.modal .modal-fit {
  max-width: fit-content !important;
  width: fit-content !important;
}
</style>
