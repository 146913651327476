<template>
    <div>
        <!-- With Widget -->
        <div class="widget widget-wfs-map" v-if="!without_widget">
            <div class="widget-heading">
                <h5 :style="title_style">
                    <padded-text :text="title" />
                </h5>
            </div>

            <div class="widget-content">
                <div :id="mapId" class="map" :style="`height: ${height}`"></div>            

                <wfs-map-popup 
                    :id="popupId"
                    :title="title"
                    :layered_features="popupFeatures"
                />
            </div>
        </div>

        <!-- Without Widget -->
        <div class="widget widget-wfs-map" v-else>
            <div :id="mapId" class="map" :style="`height: ${height}`"></div>            

            <wfs-map-popup 
                :id="popupId"
                :title="title"
                :layered_features="popupFeatures"
            />
        </div>
    </div>
</template>

<script>
    import _uniqueId from 'lodash/uniqueId';
    import 'ol/ol.css';
    import * as ol from 'ol';
    import * as olProj from 'ol/proj';
    import * as olSource from 'ol/source';
    import * as olLayer from 'ol/layer';
    import * as olStyle from 'ol/style';
    import * as olExtent from 'ol/extent';
    import Vue from 'vue';
    import WfsMapPopup from '@/components/wfs/wfs-map-popup'
    import { ColorClassifier } from '@/libraries/colors';

    Vue.component('wfs-map-popup', WfsMapPopup);

    function getFeatureCenter(feature) {
        let extent = feature.getGeometry().getExtent()
        let lon = extent[0] + (extent[2] - extent[0]) / 2
        let lat = extent[1] + (extent[3] - extent[1]) / 2
        return [lon, lat]
    }

    export default {
        props: {
            title: String,
            feature_layers: Array,
            initial_location: Array,           
            initial_zoom: Number,
            basemap: Object,
            basemap_opacity: {
                type: Number,
                default: 1.0
            },
            height: String,
            receive_features_from: Array,
            title_style: {
                type: String,
                default: ""
            },
            without_widget: {
                type: Boolean,
                default: false
            },
            fit_to_content: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                mapId: _uniqueId("map-"),
                popupId: _uniqueId("popup-"),
                map: null,
                layers: new olLayer.Group(),
                popup:  null,
                popupFeatures: {}        
            }
        },

        computed: {
            basemapLayer() {
                return new olLayer.Tile({
                    source: new olSource.TileJSON(this.basemap),
                    opacity: this.basemap_opacity
                })
            },

            mergedLayers() {
                return [this.basemapLayer].concat(this.layers)                
            }
        },

        mounted() {
            // Initialize map

            this.map = new ol.Map({
                target: this.mapId,
                view: new ol.View({
                    constrainResolution: true,
                    center: olProj.fromLonLat(this.initial_location),
                    zoom: this.initial_zoom
                }),
            })

            this.map.addLayer(this.basemapLayer)
            this.map.addLayer(this.layers)

            this.map.on("click", this.onMapClick);

            // Listen for select-feature events

            this.$root.$on('select-feature', function(event) {
                if (this.receive_features_from && this.receive_features_from.includes(event.sender)) {
                    console.log(event)
                    var view = this.map.getView();
                    view.animate({
                        center: getFeatureCenter(event.feature),
                        duration: 350
                        // zoom:   14
                    });                    
                }
            }.bind(this))
        },

        methods: {
            onMapClick(e) {
                let features = {}
                this.map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
                    if (!Object.hasOwn(features, layer.label)) {
                        features[layer.label] = {
                            layer,
                            features: []
                        }
                    }
                    features[layer.label].features.push(feature)
                })
                if (Object.keys(features).length > 0) {
                    this.popupFeatures = features
                    this.$bvModal.show(this.popupId)
                }
            }
        },

        watch: {
            feature_layers(features) {                                
                let layers = new ol.Collection([]);
                features.forEach(layer => {
                    layers.push(Layer(layer.label, layer.items, layer.color, layer.faicon, layer.colorProperty))
                })
                
                this.layers.setLayers(layers)

                if (this.fit_to_content && layers.getArray().length > 0) {
                    let layersExtent = olExtent.createEmpty();
                    layers.forEach(function(layer) {
                        olExtent.extend(layersExtent, layer.getSource().getExtent());
                    });
                    let view = this.map.getView();
                    view.fit(layersExtent, {maxZoom: 18});
                }
            }
        }
    }

    export function Layer(label, features, color, faicon, colorProperty) {
        let source = new olSource.Vector({features})

        let colorClassifier
        if (
            typeof color === 'object' &&
            !Array.isArray(color) &&
            color !== null
        ) {
            // Color classes are given
            colorClassifier = new ColorClassifier(color)
        } else {
            // Single color was given. Create a single infinite class 
            // and assign the given color
            colorClassifier = new ColorClassifier({"(,)": color})
        }

        let layer = new olLayer.Vector({
            source,
            style: function(feature) {
                let image = undefined
                let text = undefined
                let color = undefined

                if (colorProperty != undefined) {
                    color = colorClassifier.getColor(feature.get(colorProperty))
                } else {
                    // Property name for matching its values to colors was 
                    // not provided. Get the color for 0.
                    color = colorClassifier.getColor(0)
                }

                let stroke = new olStyle.Stroke({
                    color,
                    width: 2
                })

                if (faicon == undefined) {
                    image = new olStyle.Circle({
                        radius: 6,
                        fill: new olStyle.Fill({color})
                    })
                } else {
                    text = new olStyle.Text({
                        text: faicon,
                        fill: new olStyle.Fill({color}),
                        font: "normal 20px FontAwesome",
                        offsetY: 14
                    })
                }

                return new olStyle.Style({stroke, image, text})
            } 
        })
        layer.label = label

        return layer
    }    
</script>

<style lang="scss" scoped>
@import '@/assets/base/_color_variables';

.widget-wfs-map .map {
    width: 100%;
}
</style>
