import Vue from "vue";
import VueLogger from "vuejs-logger";

Vue.use(VueLogger);

export const getEnv = (variable) => {
    if (window.env && window.env[variable]) {
        Vue.$log.debug(`Getting ${variable} from window.env`);
        return window.env[variable];
    } else if (process.env[variable]) {
        Vue.$log.debug(`Getting ${variable} from process.env`);
        return process.env[variable];
    }

    Vue.$log.debug(`Value not found for variable ${variable}`);
    return null;
}
