<template>
    <span class="formatted-value">
        {{ formattedValue }}
    </span>
</template>

<script>
    export default {
        props: {
            value: Number,
            decimals: {
                type: Number,
                required: false
            }
        },        

        computed: {
            formattedValue() {
                if (this.decimals === null) {
                    if (this.value !== null) {
                        return this.value.toLocaleString()
                    }
                } else {
                    if (!isNaN(this.value)) {
                        return (this.value * 1).toFixed(this.decimals)
                    }
                }

                return this.value
            },
        }
    }
</script>

<style lang="scss">
</style>
