import Vue from 'vue';
import VueLogger from "vuejs-logger";
import App from './App.vue';
import router from './router';
import store from './store';
import Keycloak from "keycloak-js";
import { getEnv } from "./libraries/environment"

// Logger
Vue.use(VueLogger);

//bootstrap vue
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);

//perfect scrollbar
import PerfectScrollbar from 'vue2-perfect-scrollbar';
Vue.use(PerfectScrollbar);

//vue-scrollactive
import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);

//vue-meta
import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});

//Sweetalert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
    confirmButtonColor: '#4361ee',
    cancelButtonColor: '#e7515a'
};
Vue.use(VueSweetalert2, options);

//portal vue
import PortalVue from 'portal-vue';
Vue.use(PortalVue);

//vue-i18n
import i18n from './i18n';

Vue.config.productionTip = false;

// set default settings
import appSetting from './app-setting';
Vue.prototype.$appSetting = appSetting;
appSetting.init();

// Register global components
import PaddedText from '@/components/padded-text';
import FormattedValue from '@/components/formatted-value';
Vue.component('padded-text', PaddedText);
Vue.component('formatted-value', FormattedValue);

// Init KeyCloak
let keycloakOptions = {
    url: getEnv("VUE_APP_KEYCLOAK_URL"),
    realm: getEnv("VUE_APP_KEYCLOAK_REALM"),
    clientId: getEnv("VUE_APP_KEYCLOAK_CLIENT_ID"),
    onLoad: 'login-required',
    silentCheckSsoFallback: false
}
let keycloak = new Keycloak(keycloakOptions);

// Init App
keycloak.init({ onLoad: keycloakOptions.onLoad }).then((auth) => {
    if (!auth) {
        window.location.reload();
    } else {
        // Pass Keycloak instance to Vue
        Vue.prototype.$keycloak = keycloak;

        new Vue({
            router,
            store,
            i18n,
            render: h => h(App)
        }).$mount('#app');
    }

    //Token Refresh
    setInterval(() => {
        keycloak.updateToken(70).then((refreshed) => {
            if (refreshed) {
                Vue.$log.info('Token refreshed: ' + refreshed);
            }
        }).catch(() => {
            Vue.$log.error('Failed to refresh token');
        });
    }, 6000)
})
