<template>
    <div class="widget widget-wfs-weather-summary">
        <div class="widget-heading">
            <h5 :style="title_style">
                <padded-text :text="title" />
            </h5>
            <div>                
                <wfs-weather-icon 
                    :precipitation="aggregations.precipitation_sum" 
                    :total_cloudines="aggregations.total_cloudines_avg"
                />                
            </div>            
        </div>

        <div class="widget-content">

            <p class="temperature" :style="`color: ${temperature_color}`">             
                {{ getValue("temperature_max") }}<span class="unit">&deg;C</span><br/>
                <small v-if="getValue('temperature_min') != getValue('temperature_max')">{{ getValue("temperature_min") }}</small>
            </p>

            <p class="other" :style="`color: ${other_color}`">
                <span class="metric" :title="$t('wind')">
                    <i data-feather="wind" style="width: 15px"></i>
                    {{ getValue("wind_speed_avg") }} <span class="unit">m/s</span>
                </span>

                <br v-if="!horizontal">
                <span class="vr mx-2" v-else></span>

                <span class="metric" :title="$t('humidity')">
                    <i data-feather="droplet" style="width: 15px"></i>
                    {{ getValue("relative_humidity_avg") }}<span class="unit">%</span>
                </span>

                <br v-if="!horizontal">
                <span class="vr mx-2" v-else></span>
                
                <span class="metric" :title="$t('uv_radiation')">
                    <i data-feather="sun" style="width: 15px"></i>
                    <span v-if="getValue('solar_radiation_avg')">
                        {{ getValue("solar_radiation_avg") }}<span class="unit"> W/m<sup>2</sup></span>
                    </span>
                    <span v-if="getValue('uv_index_avg')">
                        {{ getValue("uv_index_avg") }}<span class="unit"> UVI</span>
                    </span>
                </span>
            </p>

        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _merge from 'lodash/merge';
    import { FeatureAggregator } from '@/libraries/wfs/wfs';
    import WfsWeatherIcon from '@/components/wfs/wfs-weather-icon'
    import feather from 'feather-icons';

    Vue.component('wfs-weather-icon', WfsWeatherIcon);

    export default {
        props: {
            title: String,
            features: Array,
            property: String,
            properties_map: Object,
            temperature_color: {
                type: String,
                default: "#4361ee"
            },
            other_color: {
                type: String,
                default: "#c8cee8"
            },
            horizontal: {
                type: Boolean,
                default: false
            },
            title_style: {
                type: String,
                default: ""
            }
        },

        data() {
            return {
                aggregators: {},
                aggregations: {},
            }
        },

        computed: {
            mergedMap() {
                let properties_map = {
                    temperature: {map: "temperature", aggregations: ["min", "max"]},
                    precipitation: {map: "precipitation", aggregations: ["sum"]},
                    relative_humidity: {map: "relative_humidity", aggregations: ["avg"]},
                    wind_speed: {map: "wind_speed", aggregations: ["avg"]},
                    total_cloudines: {map: "total_cloudines", aggregations: ["avg"]},
                    solar_radiation: {map: "solar_radiation", aggregations: ["avg"]},
                    uv_index: {map: "uv_index", aggregations: ["avg"]}
                }

                _merge(properties_map, this.properties_map)

                return properties_map
            },
        },

        mounted() {
            feather.replace();
        },

        methods: {
            splitFeatures(features) {
                let splitted = {}

                let properties = Object.keys(this.mergedMap)
                properties.forEach(function(property) {                    
                    splitted[property] = features.filter(function(feature) {
                        return feature.get("metric_id") == this.mergedMap[property].map
                    }.bind(this))
                }.bind(this)) 

                return splitted
            },

            getValue(property) {
                if (Object.hasOwn(this.aggregations, property)) {
                    return Math.round(this.aggregations[property])
                }
                return "--"
            }
        },

        watch: {
            features(features) {
                let splitted = this.splitFeatures(features)

                let properties = Object.keys(this.mergedMap)
                properties.forEach(function(property) {
                    this.aggregators[property] = new FeatureAggregator(splitted[property])
                    this.mergedMap[property].aggregations.forEach(function(aggregation) {
                        this.aggregators[property].addAggregation("value", aggregation, `${property}_${aggregation}`)
                    }.bind(this))
                    this.aggregators[property].aggregate()
                    this.aggregations = {...this.aggregations, ...this.aggregators[property].getAll()}
                }.bind(this))
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@/assets/base/_color_variables';

.widget-wfs-weather-summary .temperature {
    font-size: 26px;
    color: $primary;
    line-height: 1;
}
.widget-wfs-weather-summary .unit {
    color: $m-color_6
}
.widget-wfs-weather-summary .comparison {
    color: $m-color_6
}

.widget-wfs-weather-summary .other .metric {
    white-space: nowrap;
}
</style>
