<template>
    <div class="widget widget-wfs-chart">
        <div class="widget-heading">
            <h5 :style="title_style">
                <padded-text :text="title" />
            </h5>
        </div>

        <div class="widget-content">
            <apexchart :height="height" :type="this.type" :options="mergedOptions" :series="series"></apexchart>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueApexCharts from 'vue-apexcharts';
    import _merge from 'lodash/merge';
    import { FeatureSeries } from '@/libraries/wfs/wfs';

    Vue.component('apexchart', VueApexCharts);

    export default {
        props: {
            title: String,
            type: String,
            features: Array,
            properties: Array,
            xaxis_property: String,
            units: String,
            colors: Array,
            options: Object,
            height: {
                type: Number,
                default: 350
            },
            title_style: {
                type: String,
                default: ""
            }
        },

        data() {
            return {
                seriesGenerator: new FeatureSeries()
            }
        },

        computed: {
            series() {
                return this.seriesGenerator.getSeries()
            },
            xaxis() {
                return this.seriesGenerator.getXAxis()
            },
            mergedOptions() {
                let options = {
                    chart: { toolbar: { show: true } }, 
                    dataLabels: { enabled: false },                     
                    plotOptions: { 
                        bar: { horizontal: false, columnWidth: '50%', borderRadius: 2 }, 
                    },
                    colors: this.colors,
                    xaxis: { categories: [] },
                    yaxis: { decimalsInFloat: 0 },
                    fill: { opacity: 1 },
                    tooltip: { y: { formatter: function (val) { return val.toLocaleString() } } }
                }

                _merge(options, this.options)

                if (this.xaxis.length > 0) {
                    options.xaxis.categories = this.xaxis
                }               

                if (options.plotOptions.bar.horizontal == true) {
                    options.xaxis.title = {text: this.units}
                } else {
                    options.yaxis.title = {text: this.units}
                }

                return options
            }
        },

        mounted() {
        },

        methods: {

        },

        watch: {
            features(features) {
                this.seriesGenerator = new FeatureSeries(features)
                this.properties.forEach(property => {
                    this.seriesGenerator.addSeries(property.name, property.label)
                })
                this.seriesGenerator.setXAxis(this.xaxis_property)
                this.seriesGenerator.getXAxis()
                this.seriesGenerator.generate()
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@/assets/base/_color_variables';
</style>
